// Avoid `console` errors in browsers that lack a console.
(function() {
  var method;
  var noop = function () {};
  var methods = [
    'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
    'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
    'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
    'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
  ];
  var length = methods.length;
  var console = (window.console = window.console || {});

  while (length--) {
    method = methods[length];

    // Only stub undefined methods.
    if (!console[method]) {
      console[method] = noop;
    }
  }
}());

// Place any jQuery/helper plugins in here.
const Slideout = require('slideout/dist/slideout.min');
const slideout = new Slideout({
  'panel': document.getElementById('container'),
  'menu': document.getElementById('mobile-navi'),
  'duration': 200,
  'padding': 256,
  'side': 'right',
  'touch': false,
});

// Toggle button
const menuSwitch = document.getElementById('menu-toggle');
menuSwitch.addEventListener('click', function() {
  slideout.toggle();
  this.classList.toggle('is-active');
});

(function () {
  let timer = 0;
  window.onresize = function () {
    if (timer > 0) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      if (window.matchMedia('(min-width:600px)').matches) {
        if (slideout.isOpen()) {
          menuSwitch.classList.remove('is-active');
        }
        slideout.close();
      }
    }, 100);
  };
}());

// Addocrdion https://michu2k.github.io/Accordion/
const Accordion = require('accordion-js/dist/accordion.min');
new Accordion('.ac-container', {
  duration: 100,
  showMultiple: true,
});
